import styled from "styled-components";

export default styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 1px;
  margin: 0;
  text-align: center;
  color: #000000;
  /* padding-bottom: 50px; */
`;
